import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'

import '../styles/events.sass'

const EventsPage = ({ data }) => {
    const { edges: events } = data.allMarkdownRemark
    const categories = ['Workshops', 'Competitions', 'Conferences']
    const past_events = events.filter(({ node: e }) =>
        before_today(e.frontmatter.date)
    )
    const curr_events = events.filter(
        ({ node: e }) => !before_today(e.frontmatter.date)
    )
    return (
        <Layout languageSwitchLink="/en/events">
            <SEO title="Activités" />
            <div className="events-page-container">
                <h1>Évènements à venir</h1>
                {curr_events.length == 0 ? (
                    <div className="no-events">
            Rien pour le moment... Suivez-nous sur les réseaux sociaux pour rester informé(e)!
                    </div>
                ) : (
                    <div className="events-items-container">
                        {curr_events.map(({ node: e }) => EventsPageItem(e.frontmatter))}
                    </div>
                )}
                <h1>Événements passés</h1>
                <h2 className="events-subheading">Compétitions CTF</h2>
                <div className="events-items-container">
                    {past_events.map(({ node: e }) => {
                        return (
                            e.frontmatter.eventType === 'Competitions' &&
              EventsPageItem(e.frontmatter)
                        )
                    })}
                </div>
                <h2 className="events-subheading">Conférences</h2>
                <div className="events-items-container">
                    {past_events.map(({ node: e }) => {
                        return (
                            e.frontmatter.eventType === 'Conferences' &&
              EventsPageItem(e.frontmatter)
                        )
                    })}
                </div>
                <h2 className="events-subheading">Workshops</h2>
                <div className="events-items-container">
                    {past_events.map(({ node: e }) => {
                        return (
                            e.frontmatter.eventType === 'Workshops' &&
              EventsPageItem(e.frontmatter)
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
}

const before_today = (d) => {
    var today = new Date()
    var targ = new Date(d)
    var prev_year = targ.getFullYear() < today.getFullYear()
    var prev_month =
    targ.getFullYear() == today.getFullYear() &&
    targ.getMonth() < today.getMonth()
    var prev_day =
    targ.getFullYear() == today.getFullYear() &&
    targ.getMonth() == today.getMonth() &&
    targ.getDate() < today.getDate()
    return prev_year || prev_month || prev_day
}

const EventsPageItem = ({
    path,
    image: {
        childImageSharp: { fluid },
    },
    title,
    date,
    eventType,
}) => {
    return (
        <div className="events-page-item" key={path}>
            <Link to={path}>
                <div className="image-container">
                    <Img fluid={fluid} />
                </div>
                <div className="text">
                    <span className="title">{title}</span>
                    <br />
                    <span className="details">{date}</span>
                </div>
            </Link>
        </div>
    )
}

export const pageQuery = graphql`
  query EventsQuery {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/events/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            eventType
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default EventsPage
